import {CardMedia} from '@mui/material';

export type InfoImageProps = {
  data: Uint8Array;
  altText?: string;
};

const InfoImage = function (props: InfoImageProps) {
  const {data, altText} = props;

  // Create a Blob from the Uint8Array
  const blob = new Blob([data], {
    type: 'image/jpeg',
  });
  // Create a URL for the Blob
  const imageUrl = URL.createObjectURL(blob);

  return (
    <CardMedia
      component="img"
      image={imageUrl}
      title={altText}
      aria-label="Info image"
      data-testid="info-image"
      sx={{
        maxWidth: '100%',
        maxHeight: '100%',
        objectFit: 'scale-down',
      }}
    />
  );
};

export default InfoImage;
