import {Step} from '@verily-src/bundle-utility';
import {
  Info as InfoComponent,
  PendoContext,
  PENDO_DEFAULT_VALUE,
  usePendoState,
} from '@verily-src/content-renderer-library';
import {api} from '@verily-src/phaf-unified-api';
import {CircularProgress} from '@verily-src/react-design-system';
import {type api as VerilyMeApi} from '@verily-src/verily-me-api';
import {Info as InfoPb} from '@verily-src/verily1-protos/content/bff/api/v1/content_service';
import {useSearchParams} from 'react-router-dom';
import {getInfo} from '../../api/getInfo';
import styles from './info.css';

import {useEffect, useState} from 'react';

export default function Info() {
  // Code that combines Andorid and iOS callback.
  const [searchParams] = useSearchParams();
  const {pendoState, setPendoState} = usePendoState();
  const [loading, setLoading] = useState<boolean>(true);
  const [step, setStep] = useState<null | Step>(null);
  const [info, setInfo] = useState<InfoPb | undefined>(undefined);
  const [buttonText, setButtonText] = useState<string>('next');
  const [backTooltipText, setBackTooltipText] = useState<string>('Back');
  const [closeTooltipText, setCloseTooltipText] = useState<string>('Exit');
  const [backButtonEnabled, setBackButtonEnabled] = useState<boolean>(true);

  const stepQueryParam = searchParams.get('step');

  useEffect(() => {
    if (info !== undefined && step !== null) {
      setPendoState({
        pendoActive: true,
        pendoTags: {
          'pendo-bundle-analytics-id':
            step?.bundlePlanId ?? PENDO_DEFAULT_VALUE,
          'pendo-bundle-analytics-title':
            step?.bundlePlanName ?? PENDO_DEFAULT_VALUE,
          'pendo-info-analytics-id': info?.objectId ?? PENDO_DEFAULT_VALUE,
          'pendo-localized-info-analytics-title':
            info?.title ?? PENDO_DEFAULT_VALUE,
        },
      });
    }
  }, [info, step]);

  useEffect(() => {
    setLoading(true);
    verilyMeApi()
      .bundle?.getCurrentStep()
      .then((stepInfo: Step) => {
        setStep(stepInfo);
        setButtonText(stepInfo.nextButtonLabel);
        setBackTooltipText(stepInfo.backButtonLabel);
        setCloseTooltipText(stepInfo.closeButtonLabel);
        setBackButtonEnabled(stepInfo.backButtonState == 'enabled');
        getInfoBff(stepInfo.actionName);
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        console.error(err);
      });
  }, [stepQueryParam]);

  function verilyMeApi() {
    return api as typeof VerilyMeApi;
  }

  async function getInfoBff(actionName: string) {
    try {
      const infoCard = await getInfo(stepQueryParam, actionName);
      setInfo(infoCard);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div className={styles.infoPage}>
      <PendoContext.Provider value={pendoState}>
        {loading && (
          <div className={styles.spinnerOverlay}>
            <CircularProgress data-testid="spinner" />
          </div>
        )}
        {info && (
          <InfoComponent
            title={info.title}
            subtitle={info.subtitle}
            description={info.description}
            attribution={info.attribution}
            image={{
              data: info.infoImage?.data,
              altText: info.infoImage?.altText,
            }}
            onNext={verilyMeApi().bundle.advance}
            onBack={verilyMeApi().bundle.back}
            onExit={verilyMeApi().bundle.exit}
            backTooltipText={backTooltipText}
            closeTooltipText={closeTooltipText}
            buttonText={buttonText}
            backButtonEnabled={backButtonEnabled}
            bundleType={info.bundleType}
          />
        )}
      </PendoContext.Provider>
    </div>
  );
}
