import {Box, Typography} from '@mui/material';
// eslint-disable-next-line node/no-extraneous-import
import {
  ArrowBackIcon,
  Button,
  CloseIcon,
  Tooltip,
} from '@verily-src/react-design-system';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import InfoImage from '../components/InfoImage/InfoImage';
import {BundleType} from '@verily-src/verily1-protos/content/bff/api/v1/content_service';
import {config} from '@verily-src/phaf-runtime-helpers';

// Responsive size for the rendered survey container
import {useScreenSize} from '../util';

import {headerStyles, infoStyles, footerStyles} from './Info.styles';

import {
  PENDO_BACK_BUTTON,
  PENDO_CLOSE_BUTTON,
  PENDO_NEXT_BUTTON,
  usePendoContext,
} from '../pendo';

export type Image = {
  data?: Uint8Array;
  altText?: string;
};

export type InfoProps = {
  title: string;
  subtitle: string;
  description: string;
  attribution?: string;
  image: Image;
  onNext: () => void;
  onBack: () => void;
  onExit: () => void;
  backTooltipText?: string;
  closeTooltipText?: string;
  buttonText?: string;
  backButtonEnabled?: boolean;
  bundleType: BundleType;
};

const Info = function (props: InfoProps) {
  const {
    title,
    subtitle,
    description,
    attribution,
    image,
    onNext,
    onBack,
    onExit,
    backTooltipText,
    closeTooltipText,
    buttonText,
    backButtonEnabled,
    bundleType,
  } = props;
  const {screenSize} = useScreenSize();
  const {pendoTags, pendoActive} = usePendoContext();
  const titleVariant =
    screenSize === 'large'
      ? 'display6'
      : screenSize === 'medium'
        ? 'display3'
        : 'display4';

  return (
    <>
      {bundleType !== BundleType.PRE_QUALIFICATION_SURVEY && (
        <Box sx={headerStyles.outerContainer}>
          <Box
            sx={{
              ...headerStyles.innerContainer,
              ...headerStyles.innerContainerPadding[screenSize],
            }}
          >
            <Tooltip title={closeTooltipText}>
              <Button
                color="neutral"
                variant="filled"
                onClick={onExit}
                icon={<CloseIcon />}
                role="button"
                id="exit-button"
                aria-label="Exit"
                // TODO (ONEVERILY-47468): Remove feature flag from condition
                {...(config.getBoolean('FEATURE_CONTENT_PENDO_ENABLED') &&
                  pendoActive && {
                    'pendo-id': PENDO_CLOSE_BUTTON,
                    ...pendoTags,
                  })}
                sx={{
                  backgroundColor: 'background.secondaryCanvas',
                }}
              />
            </Tooltip>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          ...infoStyles.outerContainer,
          ...infoStyles.outerContainerPadding[screenSize],
        }}
      >
        {image?.data && (
          <Box>
            <InfoImage data={image.data} altText={image.altText} />
          </Box>
        )}
        <Typography variant={titleVariant} id="title">
          {title}
        </Typography>
        <Typography
          variant="display6"
          sx={{
            paddingTop: '16px',
          }}
          id="subtitle"
        >
          {subtitle}
        </Typography>
        <Box>
          <ReactMarkdown
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            rehypePlugins={[rehypeRaw] as any}
            children={description}
            components={{
              h6: ({...props}) => (
                <Box
                  sx={{
                    marginTop: '32px',
                    marginBottom: '16px',
                  }}
                >
                  <Typography variant="display6" {...props}></Typography>
                </Box>
              ),
              p: ({...props}) => (
                <>
                  <Typography
                    variant="body1"
                    {...props}
                    id="description"
                  ></Typography>
                  <br />
                </>
              ),
              a: ({...props}) => (
                <a style={{color: '#087A6A'}} role="link" {...props}></a>
              ),
            }}
          />
        </Box>
        {attribution && (
          <Box sx={infoStyles.attributionContainer}>
            <Typography variant="caption" color="text.muted" id="attribution">
              {attribution}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={footerStyles.outerContainer}>
        <Box
          sx={{
            ...footerStyles.innerContainer,
            ...footerStyles.innerContainerPadding[screenSize],
          }}
        >
          {backButtonEnabled && (
            <Tooltip title={backTooltipText}>
              <Button
                color="neutral"
                variant="tonal"
                size="large"
                role="button"
                onClick={onBack}
                icon={<ArrowBackIcon />}
                id="back-button"
                aria-label="Back"
                // TODO (ONEVERILY-47468): Remove feature flag from condition
                {...(config.getBoolean('FEATURE_CONTENT_PENDO_ENABLED') &&
                  pendoActive && {
                    'pendo-id': PENDO_BACK_BUTTON,
                    ...pendoTags,
                  })}
              />
            </Tooltip>
          )}
          <Button
            color="primary"
            variant="filled"
            size="large"
            role="button"
            onClick={onNext}
            id="next-button"
            sx={footerStyles.nextButton}
            aria-label="Next"
            // TODO (ONEVERILY-47468): Remove feature flag from condition
            {...(config.getBoolean('FEATURE_CONTENT_PENDO_ENABLED') &&
              pendoActive && {'pendo-id': PENDO_NEXT_BUTTON, ...pendoTags})}
          >
            {buttonText}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default Info;
