import {createContext, useContext, useState} from 'react';

export const PENDO_NEXT_BUTTON = 'Info Next';
export const PENDO_BACK_BUTTON = 'Info Back';
export const PENDO_CLOSE_BUTTON = 'Info Exit';
export const PENDO_DEFAULT_VALUE = 'none';

export type PendoTags = {
  'pendo-bundle-analytics-id': string;
  'pendo-bundle-analytics-title': string;
  'pendo-info-analytics-id': string;
  'pendo-localized-info-analytics-title': string;
};

export type PendoState = {
  pendoActive: boolean;
  pendoTags?: PendoTags;
};

export function usePendoState() {
  const [pendoState, setPendoState] = useState<PendoState>({
    pendoActive: false,
  });

  return {
    pendoState,
    setPendoState,
  };
}

export const PendoContext = createContext<PendoState>({
  pendoActive: false,
  pendoTags: {
    'pendo-bundle-analytics-id': PENDO_DEFAULT_VALUE,
    'pendo-bundle-analytics-title': PENDO_DEFAULT_VALUE,
    'pendo-info-analytics-id': PENDO_DEFAULT_VALUE,
    'pendo-localized-info-analytics-title': PENDO_DEFAULT_VALUE,
  },
});

export function usePendoContext() {
  return useContext(PendoContext);
}
